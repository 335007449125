import styled from 'styled-components'
import Img from 'gatsby-image/withIEPolyfill'
import Typography from '@pbds/typography'
import Box from '@pbds/box'
const { Caption } = Typography

export const StyledCaption = styled(Caption)`
  display: inline-block;
`

export const AlarmClockWrapper = styled('span')(
  ({ theme, type }) => `
  @media screen and (min-width: ${theme.breakpoints.xl}) {
    display: inline-block;
  }
  @media screen and (max-width: ${theme.breakpoints.xl}) {
    display: ${type === 'card' ? 'none' : 'inline-block'};
  }

  svg {
    height: 35px;
    width: 35px;
  }
`
)

export const StyledPageNumber = styled.span(
  ({ isCurrent }) => `
    width: 40px;
    height: 40px;
    background-color: ${isCurrent ? '#4A4AF4' : '#f2f2fa'};
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    padding-top: 6px;
    margin: 8px;
    cursor: ${isCurrent ? 'auto' : 'pointer'} ;
    color: ${isCurrent ? 'white' : '#4A4AF4'};
    border:  1px solid transparent;
    a {
      text-decoration: none;
      color: ${isCurrent ? 'white' : '#4A4AF4'};
    }
    &:hover {
      border:  1px solid #4A4AF4;
    }
    
  `
)

export const ArrowWrapper = styled.span`
  margin: 0 5px;
  svg {
    height: 30px;
    width: 30px;
  }
`

export const StyledBadge = styled(Box)(
  ({ theme }) => `
    display: inline-block;
    background-color: ${theme.colors['--ui-background-hover']};
  `
)

export const StyledImage = styled(Img)(
  ({ theme }) => `
    width: 100%;
    height: 100%;
    border-radius: ${theme.radii['--corner-radius-8']};
    `
)

export const StyledCard = styled(Box)(
  ({ theme, type }) => `
    position: relative;
    text-decoration: none;
    box-shadow: ${theme.elevation['--elevation-regular-3']};
    color: ${theme.colors['--text-dark']};
    height: 100%;
    padding: ${type === 'subscription' ? '32px 16px' : ''};


    @media screen and (min-width: ${theme.breakpoints.md}) {
      padding: ${type === 'subscription' && '32px 16px'};
    }

    @media screen and (min-width: ${theme.breakpoints.xl}) {
      padding: ${type === 'subscription' && '32px 16px'};
    }

    

    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      border-radius: inherit;
      opacity: 0;
      box-shadow: ${theme.elevation['--elevation-emphasized-4']};
      transition: opacity 0.2s ease;
    }
  
    &:hover:before {
      opacity: ${type === 'subscription' ? '0' : '0.5'};
    }
  `
)

export const CardWrapper = styled('article')(
  ({ hasPadding }) => `
  ${
    hasPadding &&
    `padding-top: 1rem;
     padding-bottom: 1rem;`
  }
  display: block;
  height: 100%;
  flex-direction: column;
`
)

export const BottomBoxWrapper = styled.div`
  margin-top: auto;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

export const ProfPicWrapper = styled(Box)(
  ({ theme }) => `
  margin-right: 8px;
  display: none;
  @media screen and (min-width: ${theme.breakpoints.xl}) {
    display: inline-block;
  }
  `
)

export const StyledSocialMediaSvg = styled.svg`
  :hover {
    cursor: pointer;
    path {
      fill: #212189;
    }
  }
`
