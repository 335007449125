import React, { useState } from 'react'
import SearchInput from '@pbds/search-input'
import { navigate } from '@reach/router'

const SearchBar = ({ id }) => {
  const [searchTerm, setSearchTerm] = useState('')
  const handleChange = event => {
    setSearchTerm(event.target.value)
  }

  const handleSubmit = e => {
    if (e.key === 'Enter') {
      navigate(`/en/search?term=${searchTerm}`)
    }
  }

  return (
    // eslint-disable-next-line
    <form onSubmit={handleSubmit} onKeyDown={handleSubmit}>
      <SearchInput
        value={searchTerm}
        onChange={handleChange}
        label="Search articles"
        name="search"
        id={id}
      />
    </form>
  )
}

export default SearchBar
