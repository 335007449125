import React from 'react'
import PropTypes from 'prop-types'
import { AlarmClock } from '@pbds/icons'

import { AlarmClockWrapper, StyledCaption } from './styled'

const ReadTime = ({ children, type }) => (
  <span>
    <AlarmClockWrapper type={type}>
      <AlarmClock />
    </AlarmClockWrapper>

    <StyledCaption variant="--caption-regular">{children}</StyledCaption>
  </span>
)

ReadTime.defaultProps = {
  children: {},
  type: '',
}

ReadTime.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
}

export default ReadTime
