import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@pbds/typography'
import { StyledBadge } from './styled'
const { Caption } = Typography

const CategoryBadge = ({ children, marginTop }) => (
  <StyledBadge marginTop={marginTop} borderRadius="--corner-radius-4">
    <Caption
      paddingX="--spacing-02"
      paddingY="--spacing-01"
      variant="--caption-semi-emphasized"
      color="#4A4AF4"
    >
      {children.toUpperCase()}
    </Caption>
  </StyledBadge>
)

CategoryBadge.defaultProps = {
  marginTop: '',
  children: '',
}

CategoryBadge.propTypes = {
  marginTop: PropTypes.string,
  children: PropTypes.node,
}

export default CategoryBadge
