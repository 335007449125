import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import favicon from './favicon/favicon.ico'

function Seo({
  lang,
  title,
  description,
  keywords,
  ogTitle,
  ogDecription,
  ogImage,
}) {
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | Talking Shop Blog`}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          name: 'keywords',
          content: keywords.map(keyword => keyword.keyword),
        },
        {
          property: `og:title`,
          content: ogTitle,
        },
        {
          property: `og:description`,
          content: ogDecription,
        },
        {
          name: 'og:site_name',
          content: 'Talking Shop Blog',
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: 'og:url',
          content: '#',
        },
        {
          name: 'og:image',
          content: ogImage.url,
        },
      ]}
    >
      <link rel="icon" href={favicon} />
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: 'en',
  title: '',
  description: ``,
  keywords: [{ keyword: '' }],
  ogTitle: '',
  ogDecription: '',
  ogImage: {
    url: '',
  },
}

Seo.propTypes = {
  lang: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.object),
  ogTitle: PropTypes.string,
  ogDecription: PropTypes.string,
  ogImage: PropTypes.shape({
    url: PropTypes.string,
  }),
}

export default Seo
