import React, { useState } from 'react'
import styled from 'styled-components'
import Grid from '@pbds/grid'
import Box from '@pbds/box'
import Typography from '@pbds/typography'
import Input from '@pbds/input'
import { PrimaryButton } from '@pbds/buttons'
import Alert from '@pbds/alert'

import BACKGROUND_XL from '../shapes/bottomsubscription-xl.svg'
import BACKGROUND_MD from '../shapes/bottomsubscription-md.svg'
import BACKGROUND_XS from '../shapes/bottomsubscription-xs.svg'

import {
  landingSubscriptionDefaultShape,
  landingSubscriptiontShape,
} from '../shared/proptypes'

import { emailIsValid, doSubscribe } from '../helpers/utils'

const { Row, Col } = Grid
const { Heading, Deck } = Typography

const InputWrapper = styled(Box)`
  margin-bottom: 16px;
  max-width: 400px;
`

export const SectionBoxStyled = styled(Box)(
  ({ theme }) => `
    position: relative;
    overflow: hidden;
    background-image: url(${BACKGROUND_XS});
    background-size: cover;
    background-position: center;
  
    @media screen and (min-width: ${theme.breakpoints.md}) {
      background-image: url(${BACKGROUND_MD});
      margin-top: 104px;
      padding: 80px 180px;
    }
    margin-top: 96px;
    padding: 92px 32px;
    background-color: #F2ECE4;
    @media screen and (min-width: ${theme.breakpoints.xl}) {
      margin-top: 144px;
      padding: 80px 400px;
      background-image: url(${BACKGROUND_XL});
    }
  `
)

const LandingSubscriptionBottom = ({
  heading,
  subHeading,
  inputPlaceholder,
  buttonText,
}) => {
  const [email, setEmail] = useState('')
  const [isEmailValid, setIsEmailValid] = useState(true)
  const [isLoading, setLoading] = useState(false)
  const [isSubscribed, setSubscriptionStatus] = useState(false)

  const handleChangeEmail = event => {
    setEmail(event.target.value)
    setIsEmailValid(emailIsValid(event.target.value))
  }

  const subscribe = async () => {
    if (email.length === 0) {
      setIsEmailValid(false)
    } else {
      setLoading(true)
      await doSubscribe(email)
      setLoading(false)
      setSubscriptionStatus(true)
    }
  }
  return (
    <SectionBoxStyled as="section">
      <Grid margin={false}>
        <Row>
          <Col horizontalAlign={{ xs: 'center', xl: 'center' }}>
            <Heading marginBottom="10px" variant="--heading-2">
              {heading}
            </Heading>
            <Deck marginBottom="16px">{subHeading}</Deck>
            {!isSubscribed ? (
              <>
                <InputWrapper
                  marginBottom="24px"
                  maxWidth="420px"
                  margin="auto"
                >
                  <Input
                    label="Email address"
                    name="email"
                    id="email"
                    error={!isEmailValid}
                    errorHelperText="Please enter a valid email"
                    value={email}
                    onChange={handleChangeEmail}
                    placeholder={inputPlaceholder}
                  />
                </InputWrapper>
                <Box flexAlign="middleCenter">
                  <PrimaryButton
                    isLoading={isLoading}
                    disabled={!isEmailValid}
                    buttonSize="--large-button"
                    onClick={() => subscribe()}
                  >
                    {buttonText}
                  </PrimaryButton>
                </Box>
              </>
            ) : (
              <Alert
                boldText="Success"
                text="Submitted! Thanks for subscribing."
                variant="success"
              />
            )}
          </Col>
        </Row>
      </Grid>
    </SectionBoxStyled>
  )
}

LandingSubscriptionBottom.defaultProps = landingSubscriptionDefaultShape

LandingSubscriptionBottom.propTypes = landingSubscriptiontShape

export default LandingSubscriptionBottom
