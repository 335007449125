import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@pbds/typography'
import styled from 'styled-components'

const { Body } = Typography

const TextWrapper = styled('span')(
  ({ marginRight }) => `
  margin-right: ${marginRight ? '4px' : 0};
  p {
    display: inline-block;
  }
  `
)

const AuthorName = ({ name }) => (
  <>
    <TextWrapper marginRight>
      <Body variant="--body-02-regular">by</Body>
    </TextWrapper>

    <TextWrapper flexDirection="column">
      <Body variant="--body-02-semi-emphasized">{name}</Body>
    </TextWrapper>
  </>
)

AuthorName.defaultProps = {
  name: '',
  secondary: false,
}

AuthorName.propTypes = {
  name: PropTypes.string,
  secondary: PropTypes.bool,
}

export default AuthorName
