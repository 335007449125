import PropTypes from 'prop-types'

export const imageDefaultProps = {
  fluid: {
    aspectRatio: 1,
    src:
      'https://www.datocms-assets.com/19671/1583859342-awardslogo2-1.png?auto=format',
    srcSet:
      'https://www.datocms-assets.com/19671/1583859342-awardslogo2-1.png?auto=format&dpr=1&w=156 156w',
    sizes: '(max-width: 800px) 100vw, 800px',
  },
  alt: '',
}

export const imageShape = {
  fluid: PropTypes.shape({
    aspectRatio: PropTypes.number,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    sizes: PropTypes.string,
  }),
  alt: PropTypes.string,
}

export const imageFixedShape = {
  fixed: PropTypes.shape({
    aspectRatio: PropTypes.number,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    sizes: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
  }),
  alt: PropTypes.string,
}

export const imageFixedDefaultShape = {
  fixed: {
    aspectRatio: 1,
    src:
      'https://www.datocms-assets.com/19671/1583859342-awardslogo2-1.png?auto=format',
    srcSet:
      'https://www.datocms-assets.com/19671/1583859342-awardslogo2-1.png?auto=format&dpr=1&w=156 156w',
    sizes: '(max-width: 800px) 100vw, 800px',
    height: '156px',
    width: '156px',
  },
  alt: '',
}

export const categoryDefaultShape = {
  name: '',
  slug: '',
}

export const categoryShape = PropTypes.shape({
  name: PropTypes.string,
  slug: PropTypes.string,
})

export const bodyNodeShape = PropTypes.shape({
  childMarkdownRemark: PropTypes.shape({
    timeToRead: PropTypes.number,
    htmlAst: PropTypes.string,
  }),
})

export const bodyNodeDefaultShape = {
  childMarkdownRemark: {
    timeToRead: 0,
    htmlAst: '',
  },
}

export const articleDefaultShape = {
  bodyNode: bodyNodeDefaultShape,
  title: '',
  category: categoryDefaultShape,
  image: imageDefaultProps,
  lang: 'en',
  slug: '/',
  manualReadTime: 0,
}

export const articleShape = {
  bodyNode: bodyNodeShape,
  title: PropTypes.string,
  category: categoryShape,
  image: imageShape,
  lang: PropTypes.string,
  slug: PropTypes.string,
  manualReadTime: PropTypes.number,
}

export const landingSubscriptionDefaultShape = {
  heading: '',
  subHeading: '',
  inputPlaceholder: '',
  buttonText: '',
}

export const landingSubscriptiontShape = {
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  inputPlaceholder: PropTypes.string,
  buttonText: PropTypes.string,
}
