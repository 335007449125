import 'whatwg-fetch'

const MAILCHIMP_API =
  'https://paybright.us15.list-manage.com/subscribe/post?u=0e6a7589e8fba313c1b21b902&amp;id=d263d32cd5'

export const doSubscribe = async email => {
  const formData = new FormData()
  formData.append('EMAIL', email)
  return fetch(MAILCHIMP_API, {
    method: 'POST',
    mode: 'no-cors',
    body: formData,
  })
    .then(res => {
      return res
    })
    .catch(err => {
      console.log(err)
      return err
    })
}

export const emailIsValid = email => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
