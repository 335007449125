import React from 'react'
import Img from 'gatsby-image/withIEPolyfill'
import { imageFixedShape, imageFixedDefaultShape } from '../shared/proptypes'

const AuthorProfilePic = ({ image }) => (
  <Img imgStyle={{ borderRadius: '50%' }} alt={image.alt} fixed={image.fixed} />
)

AuthorProfilePic.defaultProps = {
  image: imageFixedDefaultShape,
}

AuthorProfilePic.propTypes = {
  image: imageFixedShape,
}

export default AuthorProfilePic
