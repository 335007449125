import React, { useEffect, useState, useRef } from 'react'
import CSSReset from '@pbds/css-reset'
import { Link } from 'gatsby'
import Footer from '@pbds/footer'
import { ThemeProvider } from 'styled-components'
import theme from '@pbds/theme'
import Header from './Header'
import Seo from './Seo'
import './globalStyles.css'

const Layout = ({ children, location, footer, seo }) => {
  const [isSticky, setSticky] = useState(false)
  const [scrollDirection, setScrolDirection] = useState('up')

  const stickyRef = useRef(null)

  const handleOnWheel = e => {
    if (e.nativeEvent.wheelDelta > 0) {
      setScrolDirection('up')
    } else {
      setScrolDirection('down')
    }
  }
  const handleScroll = () => {
    if (stickyRef.current) {
      window.pageYOffset > stickyRef.current.getBoundingClientRect().top
        ? setSticky(true)
        : setSticky(false)
    }
  }

  typeof window === 'object' && window.addEventListener('scroll', handleScroll)

  useEffect(() => {
    return () => {
      window.removeEventListener('scroll', () => handleScroll)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div onWheel={handleOnWheel}>
      <ThemeProvider theme={theme}>
        <CSSReset />
        <Seo {...seo} />
        <Header
          scrollDirection={scrollDirection}
          isSticky={isSticky}
          location={location}
        />
        <main ref={stickyRef}>{children}</main>
        <Footer {...footer} location={location} Link={Link} />
      </ThemeProvider>
    </div>
  )
}

export default Layout
