import React from 'react'
import PropTypes from 'prop-types'
import { Location } from '@reach/router'

const PaybrightLogo = ({ width, height }) => (
  <Location>
    {({ location }) => {
      const params = location && location.search
      const { pathname } = location
      const lang = pathname.slice(1, 3)
      return (
        <a href={`https://paybright.com/${lang}/` + params}>
          <svg 
            width={width} 
            height={height} 
            viewBox="0 0 317 44"
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby="web--svg_paybright_logo_title">
            <path
              d="M37.604 10.4272C40.7172 10.4272 43.3163 11.8381 44.4016 13.826V12.0323C44.3968 11.905 44.4184 11.7781 44.4649 11.6595C44.5114 11.5409 44.5819 11.4331 44.672 11.3431C44.7621 11.253 44.8698 11.1824 44.9884 11.1359C45.107 11.0894 45.234 11.0679 45.3613 11.0727H49.417C49.5441 11.0696 49.6706 11.0922 49.7887 11.1393C49.9069 11.1863 50.0143 11.2568 50.1045 11.3465C50.1947 11.4361 50.2658 11.5431 50.3136 11.661C50.3613 11.7789 50.3847 11.9052 50.3824 12.0323V32.4423C50.3847 32.5697 50.3613 32.6963 50.3136 32.8144C50.2659 32.9326 50.1949 33.04 50.1048 33.1301C50.0147 33.2202 49.9073 33.2912 49.7892 33.3389C49.671 33.3866 49.5444 33.41 49.417 33.4077H45.3613C45.2341 33.41 45.1078 33.3866 44.9899 33.3388C44.8721 33.2911 44.7651 33.22 44.6754 33.1298C44.5858 33.0396 44.5153 32.9322 44.4682 32.814C44.4212 32.6959 44.3985 32.5694 44.4016 32.4423V30.6829C43.2135 32.6993 40.7172 34.076 37.5469 34.076C31.7489 34.076 26.3965 29.4662 26.3965 22.2516C26.3965 15.037 31.7432 10.4272 37.604 10.4272ZM38.438 28.5008C41.8997 28.5008 44.4016 25.7132 44.4016 22.2173C44.4016 18.7214 41.8997 15.9681 38.438 15.9681C37.633 15.977 36.8377 16.1454 36.0981 16.4636C35.3586 16.7819 34.6895 17.2436 34.1296 17.8221C33.5697 18.4006 33.1301 19.0844 32.8362 19.8339C32.5422 20.5835 32.3999 21.3838 32.4173 22.1888C32.4173 25.6904 35.0449 28.5008 38.438 28.5008V28.5008Z"
              fill="#4A4AF4"
            />
            <path
              d="M14.5723 10.4272C11.402 10.4272 8.86002 11.8381 7.55762 13.826V12.0323C7.56178 11.9032 7.53904 11.7747 7.49083 11.6548C7.44262 11.535 7.37001 11.4265 7.2776 11.3362C7.18519 11.246 7.07502 11.1759 6.95408 11.1306C6.83314 11.0852 6.70408 11.0655 6.57511 11.0727H2.57652C2.44986 11.0703 2.32402 11.0936 2.20654 11.141C2.08906 11.1884 1.98234 11.259 1.89276 11.3486C1.80318 11.4382 1.73258 11.5449 1.68517 11.6624C1.63777 11.7798 1.61453 11.9057 1.61686 12.0323V41.8961C1.61453 42.0228 1.63777 42.1486 1.68517 42.2661C1.73258 42.3835 1.80318 42.4903 1.89276 42.5798C1.98234 42.6694 2.08906 42.74 2.20654 42.7874C2.32402 42.8348 2.44986 42.8581 2.57652 42.8557H6.64937C6.77603 42.8581 6.90187 42.8348 7.01935 42.7874C7.13683 42.74 7.24355 42.6694 7.33313 42.5798C7.42271 42.4903 7.49331 42.3835 7.54072 42.2661C7.58812 42.1486 7.61136 42.0228 7.60903 41.8961V41.6105L7.67186 30.9057C9.01996 32.9221 11.8075 34.0132 14.5266 34.0132C20.4217 34.0132 25.6084 29.3976 25.6084 22.1888C25.6084 14.9799 20.4616 10.4272 14.5723 10.4272ZM13.6069 28.5008C10.1796 28.5008 7.58047 25.7132 7.58047 22.2173C7.54979 21.4058 7.68282 20.5964 7.97163 19.8374C8.26043 19.0785 8.6991 18.3854 9.26148 17.7995C9.82386 17.2137 10.4984 16.7471 11.245 16.4275C11.9916 16.108 12.7948 15.942 13.6069 15.9395C17.0686 15.9395 19.5648 18.7271 19.5648 22.1888C19.5648 25.6504 17.0686 28.5008 13.6069 28.5008Z"
              fill="#4A4AF4"
            />
            <path
              d="M73.3057 11.1012H68.9472C68.6674 11.0974 68.3952 11.192 68.178 11.3684C67.9609 11.5449 67.8126 11.792 67.7591 12.0666L65.8683 18.7614L63.0807 27.4041L58.4024 12.0552C58.3772 11.805 58.2641 11.5717 58.0831 11.3971C57.9022 11.2224 57.6651 11.1176 57.4141 11.1012H52.673C51.6162 11.1012 51.4848 11.7753 51.7133 12.5465L58.4709 32.4137C58.5352 32.6824 58.6866 32.9223 58.9015 33.0959C59.1165 33.2695 59.3828 33.3671 59.6591 33.3734H61.2585L58.151 41.4163C57.8311 42.3131 58.0882 42.8558 58.985 42.8558H63.3435C63.6227 42.861 63.8949 42.7676 64.1122 42.5921C64.3295 42.4166 64.478 42.1702 64.5316 41.8961L64.8173 40.8393L67.542 33.4134L74.2368 12.6207C74.4596 11.6496 74.1682 11.1012 73.3057 11.1012Z"
              fill="#4A4AF4"
            />
            <path
              d="M113.84 10.7184C114.354 10.7184 114.897 10.9412 114.897 11.9065V15.9051C114.91 16.0606 114.89 16.2171 114.838 16.3641C114.786 16.5111 114.703 16.6454 114.595 16.7578C114.486 16.8703 114.356 16.9584 114.211 17.0162C114.066 17.0741 113.91 17.1003 113.754 17.0933H112.612C109.664 17.0933 107.934 18.5956 107.934 22.44V32.4422C107.93 32.5642 107.902 32.6843 107.852 32.7955C107.801 32.9068 107.73 33.007 107.64 33.0903C107.551 33.1737 107.446 33.2386 107.332 33.2813C107.218 33.3239 107.096 33.3436 106.974 33.339H102.907C102.782 33.3382 102.659 33.3128 102.544 33.264C102.429 33.2152 102.325 33.1441 102.238 33.0549C102.151 32.9656 102.082 32.8599 102.036 32.7439C101.99 32.628 101.968 32.504 101.97 32.3793V11.9351C101.966 11.8075 101.988 11.6805 102.035 11.5618C102.083 11.4432 102.154 11.3356 102.244 11.2456C102.335 11.1556 102.443 11.0851 102.562 11.0387C102.681 10.9922 102.808 10.9707 102.935 10.9754H106.78C107.026 10.9677 107.265 11.0577 107.445 11.2258C107.624 11.3939 107.73 11.6263 107.739 11.8723V13.9858C108.273 12.9954 109.063 12.1666 110.027 11.5858C110.99 11.005 112.092 10.6936 113.217 10.6841C113.424 10.7132 113.632 10.7247 113.84 10.7184V10.7184Z"
              fill="#2ABC7A"
            />
            <path
              d="M115.508 4.30928C115.514 3.57626 115.736 2.86125 116.147 2.25412C116.558 1.64698 117.139 1.17478 117.817 0.896873C118.496 0.618962 119.241 0.547727 119.96 0.692122C120.678 0.836518 121.338 1.1901 121.857 1.70844C122.375 2.22679 122.728 2.88676 122.873 3.60544C123.017 4.32413 122.946 5.06946 122.668 5.74778C122.39 6.4261 121.918 7.00715 121.311 7.41793C120.704 7.82871 119.989 8.05088 119.256 8.05653C118.262 8.05653 117.309 7.66173 116.606 6.95899C115.903 6.25624 115.508 5.30311 115.508 4.30928ZM117.222 33.3105C117.095 33.3112 116.969 33.2868 116.851 33.2386C116.733 33.1905 116.626 33.1195 116.536 33.0298C116.446 32.94 116.374 32.8334 116.325 32.7159C116.276 32.5984 116.251 32.4724 116.251 32.3451V11.9352C116.246 11.8078 116.268 11.6809 116.314 11.5623C116.361 11.4437 116.431 11.336 116.521 11.2459C116.612 11.1558 116.719 11.0853 116.838 11.0387C116.956 10.9922 117.083 10.9707 117.211 10.9755H121.209C121.337 10.9707 121.464 10.9922 121.582 11.0387C121.701 11.0853 121.809 11.1558 121.899 11.2459C121.989 11.336 122.059 11.4437 122.106 11.5623C122.152 11.6809 122.174 11.8078 122.169 11.9352V32.2823C122.163 32.5355 122.06 32.7769 121.882 32.9565C121.703 33.1362 121.463 33.2404 121.209 33.2476H117.211L117.222 33.3105Z"
              fill="#2ABC7A"
            />
            <path
              d="M193.663 30.0088L192.252 27.7239C191.864 27.1127 191.11 27.1127 190.458 27.564C189.83 27.9845 189.095 28.2165 188.339 28.2323C187.282 28.2323 185.78 27.564 185.78 25.3762V15.9395H192.252C192.505 15.9337 192.746 15.8307 192.924 15.652C193.103 15.4733 193.206 15.2325 193.212 14.9799V11.9067C193.212 11.6516 193.111 11.4069 192.931 11.226C192.751 11.0452 192.507 10.9428 192.252 10.9413H185.78V6.32576C185.784 6.19666 185.762 6.0681 185.713 5.94826C185.665 5.82842 185.592 5.71993 185.5 5.62967C185.408 5.53941 185.297 5.46937 185.177 5.42399C185.056 5.37862 184.927 5.3589 184.798 5.3661H180.73C180.603 5.36043 180.476 5.38136 180.357 5.42758C180.238 5.4738 180.129 5.54429 180.039 5.63456C179.949 5.72483 179.878 5.83291 179.832 5.95191C179.786 6.07091 179.765 6.19823 179.771 6.32576V11.0041H176.692C176.565 11.004 176.44 11.0295 176.323 11.079C176.206 11.1285 176.101 11.201 176.013 11.2922C175.925 11.3834 175.856 11.4914 175.811 11.6097C175.766 11.728 175.745 11.8543 175.749 11.9809V15.0541C175.746 15.1813 175.769 15.3077 175.816 15.4259C175.863 15.544 175.933 15.6514 176.023 15.7416C176.113 15.8318 176.22 15.9029 176.338 15.9507C176.456 15.9984 176.582 16.0218 176.709 16.0195H179.788V26.77C179.788 31.8025 183.535 33.7904 187.185 33.7904C189.75 33.7904 192.024 33.0192 193.497 31.5454C193.712 31.3566 193.849 31.094 193.879 30.8094C193.91 30.5249 193.833 30.2392 193.663 30.0088Z"
              fill="#2ABC7A"
            />
            <path
              d="M142.71 33.2476V30.2944C141.567 32.0937 139.088 33.4704 135.952 33.4704C129.829 33.4704 124.671 29.1119 124.671 21.9316C124.671 14.7513 129.926 10.3357 135.724 10.3357C138.9 10.3357 141.59 11.7752 142.71 13.7288V11.9351C142.708 11.8085 142.731 11.6826 142.778 11.5652C142.826 11.4477 142.896 11.3409 142.986 11.2514C143.075 11.1618 143.182 11.0912 143.3 11.0438C143.417 10.9964 143.543 10.9731 143.67 10.9755H147.668C147.795 10.9707 147.922 10.9922 148.041 11.0387C148.16 11.0852 148.267 11.1558 148.357 11.2458C148.448 11.3359 148.518 11.4437 148.565 11.5623C148.611 11.6809 148.633 11.8078 148.628 11.9351V33.4076C148.628 39.5882 144.058 43.8839 136.632 43.8839C130.674 43.8839 126.316 40.1023 125.259 35.841C125.218 35.7494 125.198 35.6502 125.198 35.55C125.199 35.4497 125.222 35.3509 125.264 35.26C125.306 35.1691 125.367 35.0883 125.443 35.023C125.519 34.9577 125.608 34.9094 125.704 34.8813H130.097C130.668 34.8813 130.994 34.9442 131.154 35.264C131.662 36.932 133.393 38.6628 136.729 38.6628C140.813 38.7542 142.71 36.5779 142.71 33.2476ZM136.718 28.1808C140.179 28.1808 142.681 25.5532 142.681 22.063C142.681 18.5728 140.179 15.9394 136.718 15.9394C135.919 15.9319 135.127 16.0865 134.39 16.3937C133.653 16.7009 132.986 17.1544 132.429 17.7268C131.872 18.2992 131.437 18.9786 131.15 19.7239C130.863 20.4692 130.73 21.2649 130.76 22.063C130.76 25.5532 133.29 28.1808 136.718 28.1808Z"
              fill="#2ABC7A"
            />
            <path
              d="M89.2317 10.2672C86.0557 10.2672 83.4281 11.7124 82.3085 13.5689V8.86196L82.2456 2.54418C82.2384 2.29098 82.1342 2.05023 81.9545 1.87164C81.7749 1.69306 81.5335 1.59027 81.2803 1.58452H77.2817C77.1544 1.57971 77.0274 1.60125 76.9088 1.64777C76.7902 1.6943 76.6825 1.76481 76.5924 1.8549C76.5023 1.94499 76.4318 2.05272 76.3853 2.17132C76.3387 2.28993 76.3172 2.41687 76.322 2.54418L76.3848 8.86196V32.2823C76.3817 32.4094 76.4044 32.5359 76.4514 32.654C76.4985 32.7722 76.569 32.8796 76.6586 32.9698C76.7483 33.06 76.8553 33.1311 76.9732 33.1788C77.091 33.2266 77.2173 33.25 77.3445 33.2476H81.3431C81.47 33.2492 81.596 33.2252 81.7135 33.1772C81.831 33.1292 81.9377 33.058 82.0272 32.968C82.1167 32.878 82.1872 32.7709 82.2345 32.6531C82.2818 32.5353 82.3051 32.4092 82.3028 32.2823V30.4886C83.5823 32.5108 85.9872 33.8874 89.1575 33.8874C95.024 33.8874 100.314 29.2719 100.314 22.063C100.314 14.8541 94.9954 10.2672 89.2317 10.2672ZM88.3006 28.4036C84.9018 28.4036 82.3427 25.616 82.3427 22.1201C82.3427 18.6242 84.9018 15.8709 88.3006 15.8709C89.1059 15.8798 89.9015 16.0482 90.6413 16.3664C91.3812 16.6845 92.0506 17.1462 92.611 17.7246C93.1714 18.303 93.6116 18.9868 93.9061 19.7363C94.2007 20.4859 94.3437 21.2864 94.3271 22.0916C94.2585 25.5875 91.688 28.4036 88.3006 28.4036Z"
              fill="#2ABC7A"
            />
            <path
              d="M165.496 10.2672C161.937 10.2672 159.372 11.7753 158.07 13.5689V2.51566C158.061 2.26969 157.955 2.03726 157.775 1.8692C157.596 1.70114 157.356 1.61112 157.11 1.61884H153.038C152.911 1.61651 152.785 1.63974 152.668 1.68715C152.55 1.73455 152.443 1.80515 152.354 1.89473C152.264 1.98431 152.194 2.09103 152.146 2.20852C152.099 2.326 152.076 2.45183 152.078 2.5785V32.2823C152.075 32.4094 152.097 32.5359 152.145 32.6541C152.192 32.7722 152.262 32.8796 152.352 32.9698C152.441 33.06 152.548 33.1311 152.666 33.1789C152.784 33.2266 152.91 33.25 153.038 33.2477H157.11C157.357 33.2539 157.596 33.1626 157.776 32.9936C157.955 32.8246 158.061 32.5916 158.07 32.3451V21.5832C158.07 18.1216 160.309 15.7796 163.645 15.7796C167.107 15.7796 169.061 18.0645 169.061 21.5832V32.3451C169.064 32.4674 169.092 32.5878 169.142 32.6994C169.192 32.8109 169.264 32.9115 169.353 32.9953C169.442 33.0791 169.547 33.1445 169.662 33.1879C169.776 33.2312 169.898 33.2515 170.02 33.2477H174.019C174.141 33.2515 174.263 33.2312 174.377 33.1879C174.492 33.1445 174.597 33.0791 174.686 32.9953C174.775 32.9115 174.846 32.8109 174.897 32.6994C174.947 32.5878 174.975 32.4674 174.978 32.3451V19.9781C175.087 14.4372 171.391 10.2672 165.496 10.2672Z"
              fill="#2ABC7A"
            />
            <path
              d="M210.417 17.059H212.285V23.0283H212.44C212.754 22.3744 213.255 21.8279 213.879 21.457C214.503 21.0862 215.222 20.9075 215.947 20.9434C219.009 20.9434 221.122 23.3768 221.122 27.1926C221.122 31.0084 219.009 33.4761 215.981 33.4761C215.249 33.5065 214.524 33.322 213.896 32.9454C213.268 32.5688 212.763 32.0166 212.445 31.3568H212.217V33.219H210.417V17.059ZM215.724 31.8081C218.06 31.8081 219.26 29.7517 219.26 27.164C219.26 24.5764 218.118 22.5942 215.724 22.5942C213.439 22.5942 212.251 24.4279 212.251 27.164C212.251 29.9002 213.485 31.8081 215.724 31.8081V31.8081Z"
              fill="black"
            />
            <path
              d="M223.373 37.5204L223.847 35.881C225.206 36.2294 226.058 36.0524 226.703 34.2359L227.069 33.2876L222.585 21.1205H224.607L227.954 30.7799H228.08L231.427 21.1205H233.461L228.32 34.9842C227.623 36.8406 226.429 37.7318 224.749 37.7318C224.281 37.7515 223.814 37.6797 223.373 37.5204V37.5204Z"
              fill="black"
            />
            <path
              fill-rule="evenodd" clip-rule="evenodd"
              d="M245.08 31.0712C244.166 31.0712 243.714 30.6257 243.714 29.8888C243.714 28.5179 245.245 28.0495 248.039 27.7524C248.039 29.5861 246.805 31.0712 245.08 31.0712V31.0712ZM246.285 20.7892C244.283 20.8592 242.349 21.5344 240.738 22.7256L241.881 25.1248C243.001 24.0984 244.442 23.4926 245.959 23.4111C247.347 23.4111 248.113 23.8738 248.113 24.8049C248.113 25.4389 247.604 25.7531 246.645 25.8788C243.057 26.3472 240.247 27.3297 240.247 30.0944C240.247 32.288 241.807 33.6132 244.246 33.6132C245.048 33.6115 245.837 33.4043 246.537 33.0113C247.237 32.6184 247.825 32.0527 248.244 31.3683V33.2533H251.483V25.3533C251.483 22.0916 249.198 20.7834 246.262 20.7834"
              fill="#060809"
            />
            <path
              fill-rule="evenodd" clip-rule="evenodd"
              d="M278.822 21.1205V33.2476H282.29V27.404C282.29 24.6221 284.003 23.811 285.146 23.811C285.671 23.8062 286.186 23.9573 286.625 24.2451L287.259 21.0405C286.765 20.8449 286.237 20.7497 285.706 20.7606C284.922 20.718 284.146 20.9274 283.49 21.3581C282.834 21.7888 282.333 22.4182 282.061 23.1541V21.1205H278.822Z"
              fill="#060809"
            />
            <path
              fill-rule="evenodd" clip-rule="evenodd"
              d="M303.339 20.7663C302.563 20.7787 301.801 20.9789 301.118 21.35C300.436 21.721 299.853 22.2518 299.421 22.897C299.041 22.2461 298.496 21.7071 297.84 21.3346C297.185 20.9621 296.443 20.7693 295.69 20.7756C294.936 20.782 294.197 20.9874 293.548 21.3709C292.9 21.7545 292.364 22.3026 291.995 22.9598V21.1205H288.653V33.2476H292.12V27.0098C292.12 24.7649 293.297 23.691 294.405 23.691C295.399 23.691 296.313 24.3365 296.313 25.9759V33.2476H299.775V27.0098C299.775 24.7249 300.917 23.691 302.06 23.691C302.974 23.691 303.939 24.3593 303.939 25.9759V33.2476H307.407V24.8677C307.407 22.143 305.573 20.7663 303.357 20.7663"
              fill="#060809"
            />
            <path
              fill-rule="evenodd" clip-rule="evenodd"
              d="M270.38 21.1206H267.238V19.8924C267.238 18.2873 268.158 17.8246 268.952 17.8246C269.491 17.8305 270.021 17.9635 270.5 18.213L271.574 15.7682C270.637 15.2628 269.581 15.0182 268.518 15.0599C266.301 15.0599 263.776 16.3108 263.776 20.2009V21.1206H258.521V19.8924C258.521 18.2873 259.435 17.8246 260.235 17.8246C260.777 17.8262 261.31 17.9595 261.789 18.213L262.857 15.7682C261.918 15.268 260.864 15.0237 259.801 15.0599C257.584 15.0599 255.065 16.3108 255.065 20.2009V21.1206H253.054V23.7996H255.059V33.2477H258.527V23.7996H263.782V33.2477H267.249V23.7996H270.391L270.38 21.1206Z"
              fill="#060809"
            />
            <path
              fill-rule="evenodd" clip-rule="evenodd"
              d="M272.447 33.2477H275.914V21.1206H272.447V33.2477Z"
              fill="#060809"
            />
            <path
              fill-rule="evenodd" clip-rule="evenodd"
              d="M292.652 4.2065C283.29 4.2065 274.944 10.7071 272.579 19.0584H275.972C277.949 12.832 284.661 7.3711 292.652 7.3711C302.363 7.3711 310.748 14.7628 310.748 26.273C310.776 28.6311 310.451 30.9801 309.783 33.242H313.073L313.108 33.1277C313.653 30.8804 313.918 28.5741 313.896 26.2616C313.896 13.4261 304.545 4.18936 292.629 4.18936"
              fill="#4A4AF4"
            />
          </svg>
        </a>
      )
    }}
  </Location>
)

PaybrightLogo.defaultProps = {
  width: 201.71,
  height: 28,
}

PaybrightLogo.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
}

export default PaybrightLogo
