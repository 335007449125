import React, { useState, useEffect } from 'react'
import Box from '@pbds/box'
import styled from 'styled-components'
import Grid from '@pbds/grid'
import { Link } from 'gatsby'
import { Home } from '@pbds/icons'

import PaybrightLogo from '../../shapes/PaybrightLogo'
import SearchBar from './SearchBar'

const { Row, Col } = Grid

const MAX_SCREEN_WIDTH = '1440px'

const StyledHeader = styled(Box)(
  ({ theme, isSticky }) => `
    box-shadow: ${theme.elevation['--elevation-regular-2']};
    position: relative;
    z-index: 1;
    ${
      isSticky &&
      `background: white;
       position: fixed;
       top: 0;
       left: 0;
       width: 100%;
       z-index: 1;
       animation: moveDown 0.5s ease-in-out;
       @keyframes moveDown {
         from {
            transform: translateY(-5rem);
          }
          to {
            transform: translateY(0rem);
          }
       }
       @keyframes rotate {
         0% {
          transform: rotateY(360deg);
         }
         100% {
          transform: rotateY(0rem);
         }
       }
    `
    }
  `
)

const SearchBarWrapper = styled(Box)(
  ({ theme }) => `
    margin-bottom: 8px;
    @media screen and (min-width: ${theme.breakpoints.md}) {
      margin: 8px 0px 5px;
    }
    @media screen and (min-width: ${theme.breakpoints.xl}) {
      margin: 16px 0px 11px;
    }
  `
)

const HomeIconWrapper = styled.span`
  margin-left: 16px;
  svg {
    width: 32px;
    height: 32px;
    margin-bottom: 5px;
  }
`

const HomeIconContainer = styled(Box)(
  ({ theme }) => `
  display: none;
  @media screen and (min-width: ${theme.breakpoints.md}) {
    display: inline-block;
  }
  `
)

const StyledLine = styled.span`
  margin-left: 24px;
  border-left: 0.75px solid #212189;
  height: 100%;
`

const Header = ({ isSticky, scrollDirection }) => {
  const [isClientReady, setIsClientReady] = useState()
  useEffect(() => {
    setIsClientReady(true)
  }, [])
  return (
    <StyledHeader as="header" isSticky={isSticky}>
      <Box
        maxWidth={MAX_SCREEN_WIDTH}
        marginX="auto"
        paddingX={[
          '--spacing-03',
          '--spacing-03',
          '--spacing-04',
          '--spacing-04',
          '--spacing-07',
        ]}
      >
        <Grid margin={false} gutter={false} limitWidth={false}>
          <Row verticalAlign="middle">
            <Col xs={7} md={5} xl={6}>
              <Box flexDirection="row" flexAlign="middleLeft">
                <Box marginY={['12px', '12px', '20px', '20px', '28px']}>
                  <PaybrightLogo />
                </Box>
                <HomeIconContainer>
                  <StyledLine />

                  <HomeIconWrapper>
                    <Link to="/en/">
                      <Home />
                    </Link>
                  </HomeIconWrapper>
                </HomeIconContainer>
              </Box>
            </Col>
            <Col xs={0} md={7} xl={6}>
              <Box flexAlign="middleRight" flexDirection="row">
                <SearchBarWrapper>
                  {isClientReady && <SearchBar id="top-search" />}
                </SearchBarWrapper>
              </Box>
            </Col>
            <Col horizontalAlign="end" xs={5} md={0}>
              <HomeIconWrapper>
                <Link to="/">
                  <Home />
                </Link>
              </HomeIconWrapper>
            </Col>
          </Row>
          {scrollDirection === 'up' && (
            <Row verticalAlign="middle">
              <Col xs={12} md={0}>
                <SearchBarWrapper>
                  {isClientReady && <SearchBar id="bottom-search" />}
                </SearchBarWrapper>
              </Col>
            </Row>
          )}
        </Grid>
      </Box>
    </StyledHeader>
  )
}

export default Header
